import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Welcome to Peppikart`}</h1>
    <p>{`Evolving ideas for an evolving market.`}</p>
 <div style={{
      "position": "fixed",
      "top": "0",
      "left": "0",
      "height": "60px",
      "lineHeight": "40px",
      "width": "100%",
      "background": "#1ABC9C",
      "textAlign": "center",
      "color": "#FFFFFF",
      "fontFamily": "sans-serif",
      "fontWeight": "lighter",
      "fontSize": "14px"
    }}>
        <p style={{
        "padding": "0",
        "margin": "0"
      }}>
        Peppikart will be soon at your fingertips!</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
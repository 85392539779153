import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3><p style={{
        "textAlign": "center",
        "fontSize": "50px"
      }}>{` Get in touch : `}<a parentName="h3" {...{
          "href": "https://www.linkedin.com/company/peppikart"
        }}>{`LinkedIn`}</a>{` | `}<a parentName="h3" {...{
          "href": "mailto:contact@peppikart.com"
        }}>{`contact@peppikart.com`}</a>{` `}</p></h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Why Peppikart?`}</h2>
    <p>{`Tired of scrolling through all the stuff available online? Unhappy about
wasting money just because you can't decide what to buy and from where? `}<strong parentName="p">{`We, too.`}</strong>{` `}</p>
    <p>{`Do you have a love-hate relationship with how people sell and buy certain
items online? Or Can't find the stuff you like on any e-commerce sites? `}</p>
    <p>{`Aren't the sellers delivering the things you want in the way you want? Do you feel
that some things never change for the better?`}</p>
    <blockquote>
      <p parentName="blockquote">{`Hold on tight because Peppikat has something in store for you!`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      